import React, {lazy, Suspense} from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {AUTH, CHARTS, MAIN, MAP} from "../../common/constants";
import {Auth} from "../../pages/auth";
import {Main} from "../../pages/main";
//import {MapPage} from "../../pages/mapPage";
import {ChartsPage} from "../../pages/chartsPage";


type RoutesMainType = typeof routesAuth

const MapPage= lazy(() => import("../../pages/mapPage")
    .then(({ MapPage }) => ({ default: MapPage })),
)

const routesAuth = [
    {path: MAIN, component: <Main/>},
    {path: AUTH, component: <Auth/>},
    {path: MAP, component: <MapPage/>},
]



const mapingRoutes = (rout: RoutesMainType) =>rout.map((el, index) => <Route key={index} path={el.path} element={el.component}/>)


export const RoutesComponent = () => {

    return (
        <div>
            <Routes>
                <Route path={AUTH} element={<Auth/>}/>
                <Route path={MAIN} element={<Main/>}>
                    <Route path={MAIN} element={<Navigate to={CHARTS}/>}/>
                    <Route path={CHARTS} element={<ChartsPage/>}/>
                    {/*<Route path={MAP} element={<Suspense fallback={<div>loading</div>}><MapPage/></Suspense>}/>*/}
                </Route>
            </Routes>
        </div>
    );
};

