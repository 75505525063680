import axios from "axios";

const instance = axios.create({
    baseURL: /*'http://79.174.92.234:8156/b-geo'*/  'http://193.178.170.127:8156/b-geo',
})

export const mapApi = {
    getPolygons(){
        return instance.get('/polygons/')
    },
    getBuildings(lavka_title: string){
        return instance.get(`/building/?lavka_id=${lavka_title}`)
    },
    getStore(coords_sw: number[], coords_ne: number[]){
        return instance.get(`/lavka?coords_sw=${coords_sw}&coords_ne=${coords_ne}`)
    },
}