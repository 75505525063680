import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./redux/store";
import {RoutesComponent} from "./components/routes";
import {ConfigProvider} from "antd";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

      <ConfigProvider
          theme={{
          components: {
              Popover: {
                  borderRadiusLG: 20
              },
              Collapse: {
                  colorTextHeading: '#F5F5F5',
                  //contentBg: '#202020',
                  contentPadding: 0,
                  headerPadding: 0,
                  fontSize: 18,

              },
              DatePicker: {
                  colorBgContainerDisabled: 'rgba(255, 255, 255, 1)'
              },
              Checkbox: {
                  colorText: 'white',
                  lineHeight: 1.2,
                  colorPrimary: '#08A652',
                  algorithm: true,
                  fontSize: 16,
                  margin: 10

              },
              Button: {
                  colorPrimary: '#515151',
                  borderRadius: 20,
                  algorithm: true,
                  fontSize: 16,
                  borderColorDisabled: '#202020',
                  colorBgContainerDisabled: 'rgba(81,81,81,.5)',
                  colorTextDisabled: 'rgba(245,245,245,.5)',
                  colorLink: '#08A652'
              },
              Switch: {
                  colorPrimary: '#08A652',
                  algorithm: true
              },
              Table: {
                  headerBg: '#202020',
                  headerColor: '#F5F5F5',
                  headerSortHoverBg: '#515151',
                  headerSortActiveBg: '#515151',
                  bodySortBg: '#515151',
                  expandIconBg: '#F5F5F5',
                  rowHoverBg: '#515151',
                  stickyScrollBarBg: '#202020',
                  stickyScrollBarBorderRadius: 10
                  //cellPaddingBlock: 8
              },
              Form: {
                  labelColor: '#F5F5F5',
              },
              Empty: {
                  colorText: '#F5F5F5',
              },
              Card: {
                  colorBgContainer: '#202020'/*'#1d1d1d'*/,
                  colorTextHeading: '#F5F5F5',
              }
          },
          token: {
              colorPrimary: '#08A652',

              borderRadius: 2,
              fontFamily: '"SB Sans Text", "PT Sans Caption", Roboto',
              fontSize: 14,
              colorBgContainer: '#f6ffed',
          },
      }}>
          <BrowserRouter>
              <Provider store={store}>
                  <RoutesComponent/>
              </Provider>
          </BrowserRouter>
      </ConfigProvider>

);

