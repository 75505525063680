import {AppRootStateType} from '../redux/store';

export const isLoggedIn = (state: AppRootStateType) => state.auth.isLoggedIn
export const setError = (state: AppRootStateType) => state.auth.error
export const status = (state: AppRootStateType) => state.auth.status
export const loadingCharts = (state: AppRootStateType) => state.auth.loadingCharts
export const loadingTables = (state: AppRootStateType) => state.auth.loadingTables
export const loadingCategories = (state: AppRootStateType) => state.auth.loadingCategories
export const loadingCategoriesCharts = (state: AppRootStateType) => state.auth.loadingCategoriesCarts
export const loadingBanners = (state: AppRootStateType) => state.auth.loadingBanners




export const choiceOfPlatform = (state: AppRootStateType) => state.options.platform
export const selectChart = (state: AppRootStateType) => state.options.chart
export const selectTime = (state: AppRootStateType) => state.options.time
export const selectZoomedTime = (state: AppRootStateType) => state.options.zoomedTime
export const selectTimeForCategories = (state: AppRootStateType) => state.options.timeForCategories
export const selectCategory = (state: AppRootStateType) => state.options.category
export const selectMarketPlaces = (state: AppRootStateType) => state.options.marketPlacesNames
export const selectSuperMarkets = (state: AppRootStateType) => state.options.supermarketsNames
export const selectSOV = (state: AppRootStateType) => state.options.SOV
export const selectFilter = (state: AppRootStateType) => state.options.filter
export const selectHidden = (state: AppRootStateType) => state.options.hidden
export const categoriesCheckboxes = (state: AppRootStateType) => state.options.categoriesCheckboxesVisible

export const selectSwitchSOV = (state: AppRootStateType) => state.options.switches.SOV
export const selectSwitchShares = (state: AppRootStateType) => state.options.switches.shares
export const selectSwitchCategories = (state: AppRootStateType) => state.options.switches.categories
export const selectSharesChecked = (state: AppRootStateType) => state.options.switches.sharesChecked
export const selectSelloffChecked = (state: AppRootStateType) => state.options.switches.selloff

export const selectMaxSOV = (state: AppRootStateType) => state.options.valuesCharts.maxSOV
export const selectMinSOV = (state: AppRootStateType) => state.options.valuesCharts.minSOV
export const selectMaxOther = (state: AppRootStateType) => state.options.valuesCharts.maxOther
export const selectMinOther = (state: AppRootStateType) => state.options.valuesCharts.minOther
export const selectMaxMarket = (state: AppRootStateType) => state.options.valuesCharts.maxMarket
export const selectMinMarket = (state: AppRootStateType) => state.options.valuesCharts.minMarket


export const setTable = (state: AppRootStateType) => state.options.table
export const visiblePDF = (state: AppRootStateType) => state.options.visiblePDF
export const changeDataFactor = (state: AppRootStateType) => state.options.changeData
//export const selectYaxis = (state: AppRootStateType) => state.options.yaxis




export const selectDataApi = (state: AppRootStateType) => state.charts.dataAPI
export const selectDataXLSL = (state: AppRootStateType) => state.charts.dataXLSL
export const selectDataName = (state: AppRootStateType) => state.charts.dataName



export const selectTableContent = (state: AppRootStateType) => state.table.dataValueTable
/*export const selectTableContentForSpmAlways = (state: AppRootStateType) => state.table.dataValueTableSpm.dataAlways
export const selectTableContentForSpmWeekly = (state: AppRootStateType) => state.table.dataValueTableSpm.dataWeekly
export const selectTableContentForSpmOther = (state: AppRootStateType) => state.table.dataValueTableSpm.dataOther*/
export const selectTableContentForSpm = (state: AppRootStateType) => state.table.dataValueTableSpm
export const selectSortTitle = (state: AppRootStateType) => state.table.sortTitle
export const selectFilterTitle = (state: AppRootStateType) => state.table.filterTitleMps
export const selectFilterTitleSpm = (state: AppRootStateType) => state.table.filterTitleSpm
export const selectCheckboxes = (state: AppRootStateType) => state.table.viewCheckBoxes
//export const selectFilterTitleSpm = (state: AppRootStateType) => state.table.filterTitleSpm
export const selectCountProd = (state: AppRootStateType) => state.table.countProd
export const selectBanner = (state: AppRootStateType) => state.table.banners
export const bannerStatus = (state: AppRootStateType) => state.table.postBanners
export const selectSlide = (state: AppRootStateType) => state.table.slide
export const selectParams = (state: AppRootStateType) => state.table.params
export const errorBanner = (state: AppRootStateType) => state.table.error



export const selectCategories = (state: AppRootStateType) => state.categories.dataValueCategories
export const selectDataTable = (state: AppRootStateType) => state.categories.dataForTable
export const selectDataTotalTable = (state: AppRootStateType) => state.categories.dataForTotalTable
export const selectDataForCatalog = (state: AppRootStateType) => state.categories.dataForCatalog
export const selectNamesForCatalog = (state: AppRootStateType) => state.categories.namesForCatalog
//export const selectSecondDataForCatalog = (state: AppRootStateType) => state.categories.secondDataForCatalog
export const selectMps = (state: AppRootStateType) => state.categories.mps
export const selectTableChartCategories = (state: AppRootStateType) => state.categories.tableChartCategories
export const selectDateForCategoriesCharts = (state: AppRootStateType) => state.categories.dateForCategoriesCharts
export const dataForCharts = (state: AppRootStateType) => state.categories.dataForCharts
//export const datesCat = (state: AppRootStateType) => state.categories.dates


export const selectDataPriceLineChart = (state: AppRootStateType) => state.price.priceLineChart.data
export const selectTimePriceLineChart = (state: AppRootStateType) => state.price.priceLineChart.time
export const selectSpmPriceForLineChart = (state: AppRootStateType) => state.price.priceLineChart.spm

export const selectDataPriceColumnForAllChart = (state: AppRootStateType) => state.price.priceColumnForAllChart.data
export const selectOrderSpm = (state: AppRootStateType) => state.price.priceColumnForAllChart.orderSpm
export const selectTimePriceColumnForAllChart = (state: AppRootStateType) => state.price.priceColumnForAllChart.time
export const selectSpmPriceColumnForAllChart = (state: AppRootStateType) => state.price.priceColumnForAllChart.spm

export const selectDataPriceColumnCityChart = (state: AppRootStateType) => state.price.priceColumnCityChart.data
//export const selectOrderCities = (state: AppRootStateType) => state.price.priceColumnCityChart.orderCities
export const selectTimePriceColumnCityChart = (state: AppRootStateType) => state.price.priceColumnCityChart.time
export const selectCitiesPriceColumnCityChart = (state: AppRootStateType) => state.price.priceColumnCityChart.cities
export const selectSpmPriceColumnCityChart = (state: AppRootStateType) => state.price.priceColumnCityChart.spm
export const collapseChart = (state: AppRootStateType) => state.price.collapse


export const selectDataSale = (state: AppRootStateType) => state.sales.data
export const selectDataSalePercent = (state: AppRootStateType) => state.sales.dataPercent


export const selectPolygons = (state: AppRootStateType) => state.map.polygons
export const selectBuildings = (state: AppRootStateType) => state.map.buildings
export const selectStore = (state: AppRootStateType) => state.map.lavkas
export const selectCurrentPoint = (state: AppRootStateType) => state.map.currentPoint
export const selectActiveZone = (state: AppRootStateType) => state.map.activeZone
export const selectCity = (state: AppRootStateType) => state.map.city
export const selectCurrentZoom = (state: AppRootStateType) => state.map.currentZoom
export const selectCurrentBounds = (state: AppRootStateType) => state.map.currentBounds




